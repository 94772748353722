// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  demo: false,
  uat: false,
  firebaseConfig: {
    apiKey: 'AIzaSyC0TTta5egRJ0LxDSeEvd8BhLrGG9IPQRk',
    authDomain: 'mlc-dev.firebaseapp.com',
    databaseURL: 'https://mlc-dev.firebaseio.com',
    projectId: 'mlc-dev',
    storageBucket: 'mlc-dev.appspot.com',
    messagingSenderId: '526507237982',
    appId: '1:526507237982:web:33335268b6bf86bd908acf',
    measurementId: 'G-T118RNZF3H',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
